html {
  margin: 0 auto;
  scroll-behavior: smooth;
  background-color: #f8f4f1 !important;
}

body,
html {
  font-family: "Vazir", sans-serif;
  max-width: 1500px;
  background-color: #f8f4f1 !important;
}
